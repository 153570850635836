import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  listOfficeAdmin: [],
  createOffice: [],
  clearForm: [],
  message: '',
  error: '',
  alertMessage: false,
  success: false,
  detailOffice: []
}

const getters = {
  listOfficeAdmin: (state) => state.listOfficeAdmin,
  createOffice: (state) => state.createOffice,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage,
  success: (state) => state.success,
  detailOffice: (state) => state.detailOffice
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  // Admin Office List
  async getListOfficeAdmin({ commit }, req) {
    return await Api.adminRequestServer
      .post(`/${Urls.ADMIN}/${Urls.OFFICE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listOfficeAdmin', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async createOffice({ commit }, request) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    this.submitted = true
    const api = localStorage.getItem(Constants.TOKEN_ADMIN)
      ? Api.adminRequestServer
      : ''
    api
    return Api.adminRequestServer
      .post(`/${Urls.ADMIN}/${Urls.OFFICE}`, request)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
          setTimeout(() => (this.error = false), 5000)
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        setTimeout(() => (this.alertMessage = false), 5000)
        return { success: false }
      })
  },
  async removeOffice({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    const api = localStorage.getItem(Constants.TOKEN_ADMIN)
      ? Api.adminRequestServer
      : ''
    api
    return Api.adminRequestServer
      .post(`/${Urls.ADMIN}/${Urls.OFFICE}/${Urls.DELETE}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        return { success: false }
      })
  },

  async getDetailOfficeAdmin({ commit }, req) {
    return await Api.adminRequestServer
      .get(`/${Urls.ADMIN}/${Urls.OFFICE}/${req}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailOffice', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateDetailOfficeAdmin({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.adminRequestServer
      .patch(`/${Urls.ADMIN}/${Urls.OFFICE}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
