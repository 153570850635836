import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
// import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  listRecord: [],
  listLog: [],
  outputRecord: [],
  message: '',
  error: '',
  alertMessage: false,
  success: false
}

const getters = {
  listRecord: (state) => state.listRecord,
  listLog: (state) => state.listLog,
  outputRecord: (state) => state.outputRecord,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage,
  success: (state) => state.success
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getListRecord({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCREEN_CAPTURE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listRecord', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async getListLog({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.LOG_ACTIVITY}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listLog', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async exportListRecord({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCREEN_CAPTURE}/${Urls.EXPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputRecord', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
