import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import titleMixin from './mixins/titleMixin'

import defaultPlugin from './plugins/common'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import moment from 'moment-timezone'
import Multiselect from 'vue-multiselect'
import VueToast from 'vue-toast-notification'
import VeeValidate from 'vee-validate'
export const eventBus = new Vue()

// Import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})

Vue.mixin({
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }

      return null
    }
  }
})

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.mixin(titleMixin)
Vue.use(defaultPlugin)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Multiselect', Multiselect)
Vue.use(VueMoment, {
  moment
})
moment.tz.setDefault('Asia/Tokyo')
moment.locale('ja')
Vue.use(VueToast, {
  position: 'top-right'
})

// Dismiss all opened toast immediately
Vue.$toast.clear()
Vue.use(VCalendar, {
  componentPrefix: 'v',
  locale: 'ja',
  locales: {
    ja: {
      firstDayOfWeek: 2,
      masks: {
        L: 'YYYY/MM/DD'
      }
    }
  }
})
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    // console.log(el)
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event, el)
      }
    }
    el.clickInsideEvent = function (event) {
      console.log(event, 'event')
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('click', el.clickInsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

let vm = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

export default vm
