import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
// import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  listChat: [],
  detailChat: [],
  outputChat: [],
  message: '',
  error: '',
  alertMessage: false,
  success: false,
  createMessageSuccess: false,
  chatUnreadByAdmin: []
}

const getters = {
  detailChat: (state) => state.detailChat,
  listChat: (state) => state.listChat,
  outputChat: (state) => state.outputChat,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage,
  success: (state) => state.success,
  createMessageSuccess: (state) => state.createMessageSuccess,
  chatUnreadByAdmin: (state) => state.chatUnreadByAdmin
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getListChat({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.CHAT}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listChat', data.data ? data.data : []])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async showChat({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.CHAT}/${Urls.USER}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailChat', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async createChat({ commit }, req) {
    commit('set', ['createMessageSuccess', false])
    return await Api.officeRequestServer
      .post(`/${Urls.CHAT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['createMessageSuccess', data.success])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async exportChat({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.CHAT}/${Urls.EXPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputChat', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async readChatByUser({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.CHAT}/${Urls.READ}`, req)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async countChatUnreadByAdmin({ commit }, req) {
    return await Api.officeRequestServer
      .get(`/${Urls.CHAT}/${Urls.UNREAD}/?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        commit('set', ['chatUnreadByAdmin', data.data])
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
