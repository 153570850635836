import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'

// local state
const state = {
  listRecordSupport: [],
  detailRecordSupport: [],
  outputRecordSupport: [],
  success: false,
  message: '',
  error: ''
}

const getters = {
  listRecordSupport: (state) => state.listRecordSupport,
  detailRecordSupport: (state) => state.detailRecordSupport,
  outputRecordSupport: (state) => state.outputRecordSupport,
  success: (state) => state.success,
  message: (state) => state.message,
  error: (state) => state.error
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getRecordSupport({ commit }, req) {
    return await Api.officeRequestServer
      .post(`${Urls.RECORD_SUPPORT}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listRecordSupport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async createRecordSupport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .post(`/${Urls.RECORD_SUPPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async showRecordSupport({ commit }, req) {
    return await Api.officeRequestServer
      .get(`${Urls.RECORD_SUPPORT}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailRecordSupport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateRecordSupport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.RECORD_SUPPORT}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async exportRecordSupport({ commit }, req) {
    return await Api.officeRequestServer
      .post(`${Urls.RECORD_SUPPORT}/${Urls.EXPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputRecordSupport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
