import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
// import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  listUser: [],
  detailUser: [],
  listAdminSupport: [],
  detailAdminSupport: [],
  adminOffice: [],
  message: '',
  error: '',
  alertMessage: false,
  success: false
}

const getters = {
  detailAdminSupport: (state) => state.detailAdminSupport,
  listAdminSupport: (state) => state.listAdminSupport,
  detailUser: (state) => state.detailUser,
  listUser: (state) => state.listUser,
  adminOffice: (state) => state.adminOffice,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage,
  success: (state) => state.success
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async changePassword({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .post(`/${Urls.CHANGE_PASSWORD}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getListUser({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.USER}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async createUser({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .post(`/${Urls.USER}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async showUser({ commit }, req) {
    return await Api.officeRequestServer
      .get(`/${Urls.USER}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateUser({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.USER}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async deleteUser({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .delete(`/${Urls.USER}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getListAdminSupport({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.ADMIN_SUPPORT}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listAdminSupport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async createAdminSupport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .post(`/${Urls.ADMIN_SUPPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async showAdminSupport({ commit }, req) {
    return await Api.officeRequestServer
      .get(`/${Urls.ADMIN_SUPPORT}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailAdminSupport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateAdminSupport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.ADMIN_SUPPORT}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async deleteAdminSupport({ commit }, req) {
    commit('set', ['success', false])
    return await Api.officeRequestServer
      .delete(`/${Urls.ADMIN_SUPPORT}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
