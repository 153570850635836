import { Constants } from '../../Utils/config/constants'
import vm from '../../main.js'

// First we need to import axios.js
import axios from 'axios'

const baseURL = process.env.VUE_APP_ROOT_API
const userRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const adminRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const officeRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const formRequestOffice = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'multipart/form-data;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const formRequestUser = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'multipart/form-data;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const download = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

const requestDownloadOffice = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  responseType: 'blob'
})

requestDownloadOffice.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_OFFICE)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete requestDownloadOffice.defaults.headers.common.Authorization
    }
    return config
  },

  (error) => Promise.reject(error)
)

const requestDownloadUser = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  responseType: 'blob'
})

requestDownloadUser.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete requestDownloadUser.defaults.headers.common.Authorization
    }
    return config
  },

  (error) => Promise.reject(error)
)

userRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete userRequestServer.defaults.headers.common.Authorization
    }

    return config
  },

  (error) => {
    Promise.reject(error)
  }
)
userRequestServer.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem(Constants.USER_TYPE_USER)
      localStorage.removeItem(Constants.TOKEN_USER)
      localStorage.removeItem(Constants.USER_INFO_NORMAL)
      localStorage.removeItem(Constants.EXPIRES_AT_USER)

      localStorage.removeItem(Constants.USER_TYPE_ADMIN)
      localStorage.removeItem(Constants.TOKEN_ADMIN)
      localStorage.removeItem(Constants.USER_INFO_ADMIN)
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN)

      localStorage.removeItem(Constants.USER_TYPE_OFFICE)
      localStorage.removeItem(Constants.TOKEN_OFFICE)
      localStorage.removeItem(Constants.USER_INFO_OFFICE)
      localStorage.removeItem(Constants.EXPIRES_AT_OFFICE)

      vm.$router.push({ name: 'Login Page' }).catch(() => {})
    }
    return Promise.reject(error)
  }
)

formRequestOffice.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_OFFICE)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete formRequestOffice.defaults.headers.common.Authorization
    }

    return config
  },

  (error) => Promise.reject(error)
)

formRequestUser.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete formRequestUser.defaults.headers.common.Authorization
    }

    return config
  },

  (error) => Promise.reject(error)
)

formRequestOffice.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem(Constants.USER_TYPE_USER)
      localStorage.removeItem(Constants.TOKEN_USER)
      localStorage.removeItem(Constants.USER_INFO_NORMAL)
      localStorage.removeItem(Constants.EXPIRES_AT_USER)

      localStorage.removeItem(Constants.USER_TYPE_ADMIN)
      localStorage.removeItem(Constants.TOKEN_ADMIN)
      localStorage.removeItem(Constants.USER_INFO_ADMIN)
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN)

      localStorage.removeItem(Constants.USER_TYPE_OFFICE)
      localStorage.removeItem(Constants.TOKEN_OFFICE)
      localStorage.removeItem(Constants.USER_INFO_OFFICE)
      localStorage.removeItem(Constants.EXPIRES_AT_OFFICE)

      const idOffice = vm.$route.params.office_id
      vm.$router.push({ name: 'Login Office', params: { office_id: idOffice } })
    }
    return Promise.reject(error)
  }
)

formRequestUser.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem(Constants.USER_TYPE_USER)
      localStorage.removeItem(Constants.TOKEN_USER)
      localStorage.removeItem(Constants.USER_INFO_NORMAL)
      localStorage.removeItem(Constants.EXPIRES_AT_USER)

      localStorage.removeItem(Constants.USER_TYPE_ADMIN)
      localStorage.removeItem(Constants.TOKEN_ADMIN)
      localStorage.removeItem(Constants.USER_INFO_ADMIN)
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN)

      localStorage.removeItem(Constants.USER_TYPE_OFFICE)
      localStorage.removeItem(Constants.TOKEN_OFFICE)
      localStorage.removeItem(Constants.USER_INFO_OFFICE)
      localStorage.removeItem(Constants.EXPIRES_AT_OFFICE)

      const idOffice = vm.$route.params.office_id
      vm.$router.push({ name: 'Login Office', params: { office_id: idOffice } })
    }
    return Promise.reject(error)
  }
)

officeRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_OFFICE)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete officeRequestServer.defaults.headers.common.Authorization
    }

    return config
  },

  (error) => Promise.reject(error)
)

officeRequestServer.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem(Constants.USER_TYPE_USER)
      localStorage.removeItem(Constants.TOKEN_USER)
      localStorage.removeItem(Constants.USER_INFO_NORMAL)
      localStorage.removeItem(Constants.EXPIRES_AT_USER)

      localStorage.removeItem(Constants.USER_TYPE_ADMIN)
      localStorage.removeItem(Constants.TOKEN_ADMIN)
      localStorage.removeItem(Constants.USER_INFO_ADMIN)
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN)

      localStorage.removeItem(Constants.USER_TYPE_OFFICE)
      localStorage.removeItem(Constants.TOKEN_OFFICE)
      localStorage.removeItem(Constants.USER_INFO_OFFICE)
      localStorage.removeItem(Constants.EXPIRES_AT_OFFICE)

      vm.$router.push({ name: 'Login Office' })
    }
    return Promise.reject(error)
  }
)

adminRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_ADMIN)

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete adminRequestServer.defaults.headers.common.Authorization
    }

    return config
  },

  (error) => {
    Promise.reject(error)
  }
)

adminRequestServer.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      vm.$router.push({ name: 'Login Admin' })
    }
    return Promise.reject(error)
  }
)

download.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem(Constants.USER_TYPE_USER)
      localStorage.removeItem(Constants.TOKEN_USER)
      localStorage.removeItem(Constants.USER_INFO_NORMAL)
      localStorage.removeItem(Constants.EXPIRES_AT_USER)

      localStorage.removeItem(Constants.USER_TYPE_ADMIN)
      localStorage.removeItem(Constants.TOKEN_ADMIN)
      localStorage.removeItem(Constants.USER_INFO_ADMIN)
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN)

      localStorage.removeItem(Constants.USER_TYPE_OFFICE)
      localStorage.removeItem(Constants.TOKEN_OFFICE)
      localStorage.removeItem(Constants.USER_INFO_OFFICE)
      localStorage.removeItem(Constants.EXPIRES_AT_OFFICE)

      vm.$router.push({ name: 'Login Page' }).catch(() => {})
    }
    return Promise.reject(error)
  }
)

export const Api = {
  userRequestServer,
  adminRequestServer,
  officeRequestServer,
  formRequestOffice,
  formRequestUser,
  download,
  requestDownloadOffice,
  requestDownloadUser
}
