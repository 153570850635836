export const Urls = {
  CREATE: 'create',
  UPDATE: 'update',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SCHEDULE: 'schedule',
  USER: 'user',
  CHAT: 'chat',
  NOTICE: 'notice',
  OFFICE: 'office',
  DAILY: 'daily',
  LIST: 'list',
  CATEGORY: 'category',
  FILE: 'file',
  DAILY_REPORT: 'daily-report',
  ADMIN: 'admin',
  SCREEN_CAPTURE: 'screen-capture',
  EXPORT: 'export',
  CHANGE_PASSWORD: 'change-password',
  ADMIN_SUPPORT: 'admin-support',
  DELETE: 'delete',
  SETTING: 'setting',
  GENERAL: 'general',
  RECORD_SUPPORT: 'record-support',
  UPLOAD: 'upload',
  READ: 'read',
  UNREAD: 'unread',
  COUNT: 'count',
  FORM: 'form',
  LOG_ACTIVITY: 'log-activity',
  LAST: 'last',
  ATTENDANCE: 'attendance'
}
