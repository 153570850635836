import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
// import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  detailSettingGeneral: [],
  message: '',
  error: '',
  alertMessage: false,
  success: false
}

const getters = {
  detailSettingGeneral: (state) => state.detailSettingGeneral,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage,
  success: (state) => state.success
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async showSettingGeneral({ commit }, req) {
    return await Api.officeRequestServer
      .get(`/${Urls.SETTING}/${Urls.GENERAL}/${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailSettingGeneral', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateSettingGeneral({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.SETTING}/${Urls.GENERAL}/${req.office_id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
