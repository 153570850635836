import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'

// local state
const state = {
  categoryList: [],
  dataStorageList: [],
  removeFile: []
}

const getters = {
  categoryList: (state) => state.categoryList,
  dataStorageList: (state) => state.dataStorageList,
  removeFile: (state) => state.removeFile
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  // category
  async getCategoryList({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.CATEGORY}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['categoryList', data.data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getDataStorageList({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.FILE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['dataStorageList', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async removeFile({ commit }, { id }) {
    return await Api.officeRequestServer
      .delete(`${Urls.FILE}/${id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        commit('set', ['error', error.message])
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
