import Vue from 'vue'
import VueRouter from 'vue-router'
import { Constants } from '../Utils/config/constants'
import { Api } from '../Utils/config/axiosConfig'
import { Urls } from '../Utils/config/urls'

// import store from '../store'
// import VueCookies from 'vue-cookies'

// Header Default
// const HeaderDefault = () => import('@/layouts/default/header')

const Default = () => import('@/views/personal/default')

// Layout
const DefaultBody = () => import('@/layouts/default')
const DefaultSupporter = () => import('@/layouts/defaultSupporter')
const DefaultAdmin = () => import('@/layouts/defaultAdmin')

const PersonalBody = () => import('@/layouts/personal')
const SupporterBody = () => import('@/layouts/supporter')
const AdminBody = () => import('@/layouts/admin')

// Login Page
const Login = () => import('@/views/personal/login')
const LoginSuport = () => import('@/views/supporter/login')
const LoginAdmin = () => import('@/views/admin/login')

// Change Password
const ChangePassword = () => import('@/views/supporter/changePassword')

// Home Page Personal
const HomePersonal = () => import('@/views/personal/index')

// Daly Report Personal
const DailyReportList = () => import('@/views/personal/dailyReport')
const CreateDailyReport = () => import('@/views/personal/createDailyReport')
const DetailDailyReport = () => import('@/views/personal/detailDailyReport')

// Data Strorage Personal
const DataStrorage = () => import('@/views/personal/dataStorage')

// Data Storage Office
const OfficeDataStorage = () => import('@/views/supporter/dataStorage')
const OfficeDataStorageUpload = () => import('@/views/supporter/upload')

// User Page Supporter
const UserListSupport = () => import('@/views/supporter/user')

// Report Office
const ListReportUser = () => import('@/views/supporter/dailyReport')
const DetailReportUser = () => import('@/views/supporter/detailDailyReport')

// Screen Capture Office
const ScreenCapture = () => import('@/views/supporter/screenCapture')

// Chat Office
const ScreenChatOffice = () => import('@/views/supporter/chat')

// User
const UserOffice = () => import('@/views/supporter/userOffice')
const CreateUser = () => import('@/views/supporter/createUser')

// Support
const ListSupporter = () => import('@/views/supporter/listSupporter')
const CreateAdminSupport = () => import('@/views/supporter/createAdminSupport')
const CreateRecordSupport = () => import('@/views/supporter/record')
const RecordSupport = () => import('@/views/supporter/recordSupport')

// Notification Office
const NotificationOffice = () => import('@/views/supporter/notification')
const OfficeCreateNotification = () =>
  import('@/views/supporter/officeCreateNotification')
const OfficeEditNotification = () =>
  import('@/views/supporter/officeCreateNotification')

// Setting Office
const Setting = () => import('@/views/supporter/setting')
const CreateSettingForm = () => import('@/views/supporter/createForm')

// Admin
const ListOffice = () => import('@/views/admin/office')
const UpdateOffice = () => import('@/views/admin/updateOffice')

// Notification Office
const NotificationAdmin = () => import('@/views/admin/notification')
const AdminCreateNotification = () => import('@/views/admin/createNotification')
const AdminEditNotification = () => import('@/views/admin/createNotification')

const ListAttendanceSheet = () => import('@/views/supporter/attendanceSheet')
const DetailAttendanceSheet = () =>
  import('@/views/supporter/detailAttendanceSheet')

Vue.use(VueRouter)
// Vue.use(VueCookies)

const routes = [
  {
    path: '/',
    component: DefaultBody,
    children: [
      {
        path: '/',
        name: 'Default Page',
        component: Default,
        meta: {
          requiresAuth: false,
          layout: 'user'
        }
      }
    ]
  },
  {
    path: '/office/:office_id/login',
    name: 'Login Page',
    component: DefaultBody,
    children: [
      {
        path: '/office/:office_id/login',
        name: 'Login Page',
        component: Login,
        meta: {
          requiresAuth: false,
          layout: 'user'
        }
      }
    ]
  },
  {
    path: '/office/:office_id/',
    name: 'user',
    component: PersonalBody,
    children: [
      {
        path: '/office/:office_id',
        name: 'Home',
        component: HomePersonal,
        meta: {
          requiresAuth: true,
          layout: 'user'
        }
      },
      {
        path: '/office/:office_id/daily-report/list',
        name: 'Daly Report',
        component: DailyReportList,
        meta: {
          requiresAuth: true,
          layout: 'user'
        }
      },
      {
        path: '/office/:office_id/data-storage',
        name: 'Data Strorage',
        component: DataStrorage,
        meta: {
          requiresAuth: true,
          layout: 'user'
        }
      },
      {
        path: '/office/:office_id/daily-report/create',
        name: 'Create Daily Report',
        component: CreateDailyReport,
        meta: {
          requiresAuth: true,
          layout: 'user'
        }
      },
      {
        path: '/office/:office_id/daily-report/detail/:id',
        name: 'Detail Daily Report',
        component: DetailDailyReport,
        meta: {
          requiresAuth: true,
          layout: 'user'
        }
      }
    ]
  },
  // Office
  {
    path: '/office/:office_id/admin/',
    name: 'Office',
    redirect: '/office/:office_id/admin/user',
    component: DefaultSupporter,
    children: [
      {
        path: '/office/:office_id/admin/login',
        name: 'Login Office',
        component: LoginSuport,
        meta: {
          requiresAuth: false,
          layout: 'office'
        }
      }
    ]
  },
  {
    path: '/office',
    name: 'Office',
    redirect: '/office/:office_id/admin/user',
    component: SupporterBody,
    children: [
      {
        path: '/office/:office_id/admin/user',
        name: 'List User Schedule',
        component: UserListSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      }
    ]
  },
  {
    path: '/office',
    name: 'Office',
    component: SupporterBody,
    children: [
      {
        path: '/office/:office_id/admin/user/:user_id/report',
        name: 'List Report User Office',
        component: ListReportUser,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/user/:user_id/report/:id',
        name: 'Detail Report User Office',
        component: DetailReportUser,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/change-password',
        name: 'Office Change Password',
        component: ChangePassword,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/userOffice',
        name: 'List User Office',
        component: UserOffice,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/userOffice/create',
        name: 'Create User Office',
        component: CreateUser,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/userOffice/edit/:id',
        name: 'Edit User Office',
        component: CreateUser,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/supporter',
        name: 'List Supporter Office',
        component: ListSupporter,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/adminOffice/create',
        name: 'Create Admin Office',
        component: CreateAdminSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/supporter/create',
        name: 'Create Supporter Office',
        component: CreateAdminSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/supporter/edit/:id',
        name: 'Edit Admin Support Office',
        component: CreateAdminSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/setting',
        name: 'Setting Office',
        component: Setting,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/setting/form/create',
        name: 'Create Setting Form Office',
        component: CreateSettingForm,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/user/:user_id/record-support',
        name: 'List Record Support Office',
        component: RecordSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/user/:user_id/record-support/create',
        name: 'Create Record Support Office',
        component: CreateRecordSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/user/:user_id/record-support/:id/edit',
        name: 'Edit Record Support Office',
        component: CreateRecordSupport,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/data-storage',
        name: 'Office Data Storage',
        component: OfficeDataStorage,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/data-storage/upload',
        name: 'Office Upload File',
        component: OfficeDataStorageUpload,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/user/:user_id/capture',
        name: 'List Screen Capture Office',
        component: ScreenCapture,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/chat',
        name: 'Chat Screen Office',
        component: ScreenChatOffice,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/notification',
        name: 'Notification Office',
        component: NotificationOffice,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/notification/create',
        name: 'Create Notification Office',
        component: OfficeCreateNotification,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/notification/:id/edit',
        name: 'Edit Notification Office',
        component: OfficeEditNotification,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/attendance-sheet',
        name: 'List Attendance Sheet',
        component: ListAttendanceSheet,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      },
      {
        path: '/office/:office_id/admin/attendance-sheet/:id',
        name: 'Detail Attendance Sheet',
        component: DetailAttendanceSheet,
        meta: {
          requiresAuth: true,
          layout: 'office'
        }
      }
    ]
  },

  //admin
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/office',
    component: DefaultAdmin,
    children: [
      {
        path: '/admin/login',
        name: 'Login Admin',
        component: LoginAdmin,
        meta: {
          requiresAuth: false,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/office',
        name: 'List Office',
        component: ListOffice,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/office/create',
        name: 'Admin Create Office',
        component: UpdateOffice,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/office/edit/:id',
        name: 'Admin Edit Office',
        component: UpdateOffice,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/notification',
        name: 'Notification Admin',
        component: NotificationAdmin,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/notification/create',
        name: 'Create Notification Admin',
        component: AdminCreateNotification,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBody,
    children: [
      {
        path: '/admin/notification/:id/edit',
        name: 'Edit Notification Admin',
        component: AdminEditNotification,
        meta: {
          requiresAuth: true,
          layout: 'admin'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const token_user = localStorage.getItem(Constants.TOKEN_USER)
  const info_user = JSON.parse(localStorage.getItem(Constants.USER_INFO_NORMAL))
  const token_office = localStorage.getItem(Constants.TOKEN_OFFICE)
  const token_admin = localStorage.getItem(Constants.TOKEN_ADMIN)
  const idOffice = to.params.office_id

  if (to.meta.layout === 'user' && token_user && to.name === 'Login Page') {
    next({ name: 'Home', params: { office_id: idOffice } })
  }
  if (to.name == null) {
    next({ name: 'Default Page' })
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token_user) {
      if (to.meta.layout === 'admin') {
        if (!token_admin) {
          next({ name: 'Login Admin' })
        } else {
          next()
        }
      } else if (to.meta.layout === 'office') {
        if (!token_office) {
          if (idOffice) {
            next({ name: 'Login Office', params: { office_id: idOffice } })
          } else {
            next({ name: 'Login Page', params: { office_id: idOffice } })
          }
        } else {
          next()
        }
      } else if (to.meta.layout === 'user') {
        next({ name: 'Login Page', params: { office_id: idOffice } })
      }
    } else if (!token_office) {
      if (to.meta.layout === 'admin') {
        if (!token_admin) {
          next({ name: 'Login Admin' })
        } else {
          next()
        }
      } else if (to.meta.layout === 'office') {
        if (idOffice && !token_office) {
          next({ name: 'Login Office', params: { office_id: idOffice } })
        } else {
          next({ name: 'Login Page', params: { office_id: idOffice } })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }

  // Log activity
  if (info_user) {
    var nameRoute = ''
    if (to.name == 'Home') {
      nameRoute = 'ホームを開いた'
    } else if (to.name == 'Create Daily Report') {
      nameRoute = '日報を開きました'
    } else if (to.name == 'Data Strorage') {
      nameRoute = '資料保管を開きました'
    } else if (to.name == 'Daly Report') {
      nameRoute = '日報一覧を開きました'
    } else if (to.name == 'Detail Daily Report') {
      nameRoute = '詳細日報を開いた'
    }

    const req = {
      route: nameRoute,
      office_id: info_user['office_id']
    }
    Api.userRequestServer
      .post(`/${Urls.LOG_ACTIVITY}`, req)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch(() => {
        return false
      })
  }
})
export default router
