import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  scheduleByUser: [],
  outputDailyReport: [],
  detailDailyReport: [],
  success: false,
  message: '',
  error: '',
  alertMessage: false
}

const getters = {
  outputDailyReport: (state) => state.outputDailyReport,
  detailDailyReport: (state) => state.detailDailyReport,
  scheduleByUser: (state) => state.scheduleByUser,
  success: (state) => state.success,
  message: (state) => state.message,
  error: (state) => state.error,
  alertMessage: (state) => state.alertMessage
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getScheduleByUser({ commit }, req) {
    const api = localStorage.getItem(Constants.TOKEN_USER)
      ? Api.userRequestServer
      : Api.officeRequestServer
    api
    return await api
      .post(`/${Urls.SCHEDULE}/${Urls.USER}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleByUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async createDailyReport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.userRequestServer
      .post(`/${Urls.DAILY_REPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async showDailyReport({ commit }, id) {
    const api = localStorage.getItem(Constants.TOKEN_USER)
      ? Api.userRequestServer
      : Api.officeRequestServer
    api
    return await api
      .get(`/${Urls.DAILY_REPORT}/${id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailDailyReport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async updateDailyReport({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.DAILY_REPORT}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async exportDailyReport({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.DAILY_REPORT}/${Urls.EXPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputDailyReport', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
