import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  categoryList: [],
  detailCategory: [],
  dataStorageList: [],
  removeFile: [],
  uploadFile: [],
  success: false,
  successCreate: false,
  message: '',
  messageCreate: '',
  error: '',
  errorCreate: '',
  alertMessage: false
}

const getters = {
  categoryList: (state) => state.categoryList,
  detailCategory: (state) => state.detailCategory,
  dataStorageList: (state) => state.dataStorageList,
  removeFile: (state) => state.removeFile,
  success: (state) => state.success,
  message: (state) => state.message,
  messageCreate: (state) => state.messageCreate,
  error: (state) => state.error,
  errorCreate: (state) => state.errorCreate,
  alertMessage: (state) => state.alertMessage,
  uploadFile: (state) => state.uploadFile,
  successCreate: (state) => state.successCreate
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getCategoryList({ commit }, req) {
    return await Api.officeRequestServer
      .post(`${Urls.CATEGORY}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['categoryList', data.data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async createCategory({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .post(`/${Urls.CATEGORY}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async showCategory({ commit }, req) {
    return await Api.officeRequestServer
      .get(`${Urls.CATEGORY}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailCategory', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateCategory({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .patch(`/${Urls.CATEGORY}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async deleteCategory({ commit }, req) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.officeRequestServer
      .delete(`${Urls.CATEGORY}/${req.id}?office_id=${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getDataStorageList({ commit }, req) {
    return await Api.officeRequestServer
      .post(`${Urls.FILE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['dataStorageList', data.data])
          return data.data.data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async removeFile({ commit }, id) {
    commit('set', ['message', ''])
    commit('set', ['success', false])
    commit('set', ['error', false])
    const api = localStorage.getItem(Constants.TOKEN_OFFICE)
      ? Api.officeRequestServer
      : ''
    api
    return Api.officeRequestServer
      .delete(`/${Urls.FILE}/${id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        return { success: false }
      })
  },
  async uploadFile({ commit }, request) {
    commit('set', ['message', ''])
    commit('set', ['success', false])
    commit('set', ['error', false])
    return Api.formRequestOffice
      .post(`${Urls.FILE}`, request)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['messageCreate', error.message])
        setTimeout(() => (this.alertMessage = false), 5000)
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
