import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  notification: [],
  notificationByAdmin: [],
  removeNotification: [],
  createMessageSuccess: false,
  createNotification: [],
  success: false,
  message: '',
  error: ''
}

const getters = {
  notification: (state) => state.notification,
  notificationByAdmin: (state) => state.notificationByAdmin,
  removeNotification: (state) => state.removeNotification,
  createMessageSuccess: (state) => state.createMessageSuccess,
  createNotification: (state) => state.createNotification,
  success: (state) => state.success,
  message: (state) => state.message,
  error: (state) => state.error
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  async getNotificationByAdmin({ commit }, req) {
    return await Api.adminRequestServer
      .post(`/${Urls.ADMIN}/${Urls.NOTICE}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['notificationByAdmin', data.data])
          return data.data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },
  async removeNotification({ commit }, id) {
    commit('set', ['success', false])
    const api = localStorage.getItem(Constants.TOKEN_ADMIN)
      ? Api.adminRequestServer
      : ''
    api
    return await Api.adminRequestServer
      .delete(`/${Urls.NOTICE}/${id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        return { success: false }
      })
  },
  async createNotification({ commit }, request) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.adminRequestServer
      .post(`/${Urls.NOTICE}`, request)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        return { success: false }
      })
  },
  async updateNotification({ commit }, request) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    return await Api.adminRequestServer
      .patch(`/${Urls.NOTICE}/${request.id}`, request.formUpdate)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['success', true])
          commit('set', ['message', data.message])
        } else {
          commit('set', ['error', true])
          commit('set', ['message', data.message])
        }
      })
      .catch((error) => {
        commit('set', ['error', true])
        commit('set', ['message', error.message])
        return { success: false }
      })
  },
  async getNoti({ commit }, id) {
    const api = localStorage.getItem(Constants.TOKEN_ADMIN)
      ? Api.adminRequestServer
      : ''
    api
    return await api
      .get(`/${Urls.NOTICE}/${id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['notification', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
