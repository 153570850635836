import Vue from 'vue'
import Vuex from 'vuex'
import dataStorage from './dataStorage'
import topPage from './topPage'
import schedule from './schedule'
import dailyReport from './dailyReport'
import screenCapture from './screenCapture'
import adminOffice from './adminOffice'
import account from './account'
import dataStorageOffice from './dataStorageOffice'
import chat from './chat'
import notiOffice from './notiOffice'
import setting from './setting'
import recordSupport from './recordSupport'
import notiAdmin from './notiAdmin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    dataStorage,
    topPage,
    schedule,
    dailyReport,
    screenCapture,
    adminOffice,
    dataStorageOffice,
    chat,
    notiOffice,
    setting,
    recordSupport,
    notiAdmin
  }
})
