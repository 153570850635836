import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  scheduleAllUserAdmin: [],

  scheduleAllUserOffice: [],

  scheduleDailyByUser: [],

  scheduleByUser: [],

  chatByUser: [],

  noticeByUser: [],

  listCategory: [],

  listFile: [],

  listOffice: [],

  listTimeStatus: [],

  outputTimeStatus: [],

  detailTimeKeeping: [],

  outputTimeKeeping: [],

  createMessageSuccess: false
}

const getters = {
  scheduleAllUserAdmin: (state) => state.scheduleAllUserAdmin,

  scheduleAllUserOffice: (state) => state.scheduleAllUserOffice,

  scheduleDailyByUser: (state) => state.scheduleDailyByUser,

  scheduleByUser: (state) => state.scheduleByUser,

  chatByUser: (state) => state.chatByUser,

  noticeByUser: (state) => state.noticeByUser,

  listFile: (state) => state.listFile,

  listCategory: (state) => state.listCategory,

  listOffice: (state) => state.listOffice,

  listTimeStatus: (state) => state.listTimeStatus,

  outputTimeStatus: (state) => state.outputTimeStatus,

  detailTimeKeeping: (state) => state.detailTimeKeeping,

  outputTimeKeeping: (state) => state.outputTimeKeeping,

  createMessageSuccess: (state) => state.createMessageSuccess
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {
  // schedule admin
  async getScheduleAllUserInAdmin({ commit }, req) {
    return await Api.adminRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleAllUserAdmin', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // schedule office
  async getScheduleAllUserInOffice({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleAllUserOffice', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getScheduleDailyByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.USER}/${Urls.DAILY}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleDailyByUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateScheduleUser({ commit }, formData) {
    commit('set', ['success', false])
    commit('set', ['error', false])
    const api = localStorage.getItem(Constants.TOKEN_USER)
      ? Api.userRequestServer
      : Api.officeRequestServer
    return await api
      .post(`/${Urls.SCHEDULE}`, formData)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getScheduleByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.USER}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleByUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // office
  async getListOffice({ commit }, req) {
    return await Api.systemRequestServer
      .post(`/${Urls.OFFICE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listOffice', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getTimeStatus({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.ATTENDANCE}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listTimeStatus', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getTimeKeeping({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.ATTENDANCE}/${req.id}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailTimeKeeping', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async exportTimeStatus({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.ATTENDANCE}/${Urls.EXPORT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputTimeStatus', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async exportTimeKeeping({ commit }, req) {
    return await Api.officeRequestServer
      .post(
        `/${Urls.SCHEDULE}/${Urls.ATTENDANCE}/${Urls.EXPORT}/${req.id}`,
        req
      )
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['outputTimeKeeping', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
