import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { Api } from '../../Utils/config/axiosConfig'
import { Urls } from '../../Utils/config/urls'
import { Constants } from '../../Utils/config/constants'

// local state
const state = {
  scheduleAllUser: [],

  scheduleDailyByUser: [],

  scheduleByUser: [],

  chatByUser: [],

  latestChatByUser: [],

  noticeByUser: [],

  noticeAdminByUser: [],

  listCategory: [],

  listFile: [],

  listOffice: [],

  createMessageSuccess: false,

  success: false,

  message: '',

  error: '',

  alertMessage: false,

  stopTrackingTime: false,

  detailSettingGeneral: [],

  uploadImgScreenCaptureStatus: false,

  uploadImgScreenDesktopCaptureStatus: false
}

const getters = {
  scheduleAllUser: (state) => state.scheduleAllUser,

  scheduleDailyByUser: (state) => state.scheduleDailyByUser,

  scheduleByUser: (state) => state.scheduleByUser,

  chatByUser: (state) => state.chatByUser,

  latestChatByUser: (state) => state.latestChatByUser,

  noticeByUser: (state) => state.noticeByUser,

  noticeAdminByUser: (state) => state.noticeAdminByUser,

  listFile: (state) => state.listFile,

  listCategory: (state) => state.listCategory,

  listOffice: (state) => state.listOffice,

  createMessageSuccess: (state) => state.createMessageSuccess,

  success: (state) => state.success,

  message: (state) => state.message,

  error: (state) => state.error,

  alertMessage: (state) => state.alertMessage,

  stopTrackingTime: (state) => state.stopTrackingTime,

  detailSettingGeneral: (state) => state.detailSettingGeneral,

  uploadImgScreenCaptureStatus: (state) => state.uploadImgScreenCaptureStatus,

  uploadImgScreenDesktopCaptureStatus: (state) =>
    state.uploadImgScreenDesktopCaptureStatus
}

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value
  },
  uploadImgScreenCaptureStatus(state, { uploadImgScreenCaptureStatus }) {
    // ...
    state.uploadImgScreenCaptureStatus =
      uploadImgScreenCaptureStatus | state.uploadImgScreenCaptureStatus
  }
}

const actions = {
  // schedule personal
  async getScheduleAllUser({ commit }, req) {
    return await Api.officeRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleAllUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getScheduleDailyByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.SCHEDULE}/${Urls.USER}/${Urls.DAILY}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['scheduleDailyByUser', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async updateScheduleUser({ commit }, formData) {
    commit('set', ['success', false])
    commit('set', ['message', ''])
    commit('set', ['error', false])
    const api = localStorage.getItem(Constants.TOKEN_USER)
      ? Api.userRequestServer
      : Api.officeRequestServer
    return await api
      .post(`/${Urls.SCHEDULE}`, formData)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['message', data.message])
          commit('set', ['success', true])
        } else {
          commit('set', ['message', data.message])
          commit('set', ['error', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // chat
  async getChatByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.CHAT}/${Urls.USER}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['chatByUser', data.data])
          commit('set', ['createMessageSuccess', false])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async readChatByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.CHAT}/${Urls.READ}`, req)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async lastChatByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.CHAT}/${Urls.LAST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['latestChatByUser', data.data])
        }
      })

      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // notice
  async getNoticeByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.NOTICE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['noticeByUser', data.data])
          return data.data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async getNoticeAdminByUser({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.ADMIN}/${Urls.NOTICE}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['noticeAdminByUser', data.data])
          return data.data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // office
  async getListOffice({ commit }, req) {
    return await Api.systemRequestServer
      .post(`/${Urls.OFFICE}/${Urls.LIST}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['listOffice', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  // office
  async createChat({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.CHAT}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['createMessageSuccess', true])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async showSettingGeneral({ commit }, req) {
    return await Api.userRequestServer
      .get(`/${Urls.SETTING}/${Urls.OFFICE}/${req.office_id}`)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['detailSettingGeneral', data.data])
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  },

  async uploadImgScreenCapture({ commit }, req) {
    commit('set', ['uploadImgScreenCaptureStatus', false])
    return await Api.userRequestServer
      .post(`/${Urls.SCREEN_CAPTURE}/${Urls.UPLOAD}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['uploadImgScreenCaptureStatus', data.success])
          return data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['uploadImgScreenCaptureStatus', false])
        return { success: false }
      })
  },
  async uploadImgScreenDesktopCapture({ commit }, req) {
    commit('set', ['uploadImgScreenDesktopCaptureStatus', false])
    return await Api.userRequestServer
      .post(`/${Urls.SCREEN_CAPTURE}/${Urls.UPLOAD}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          commit('set', ['uploadImgScreenDesktopCaptureStatus', data.success])
          return data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['uploadImgScreenDesktopCaptureStatus', false])
        return { success: false }
      })
  },

  async logActivity({ commit }, req) {
    return await Api.userRequestServer
      .post(`/${Urls.LOG_ACTIVITY}`, req)
      .then((response) => {
        const { data } = response
        if (data.success) {
          return data
        }
      })
      .catch((error) => {
        console.log(error)
        commit('set', ['error', true])
        return { success: false }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
