export const Constants = {
  USER_TYPE_USER: 'user_type_user',
  TOKEN_USER: 'token_user',
  USER_INFO_NORMAL: 'user_info_normal',
  EXPIRES_AT_USER: 'expires_at_user',
  USER_TYPE_OFFICE: 'user_type_office',
  TOKEN_OFFICE: 'token_office',
  USER_INFO_OFFICE: 'user_info_office',
  EXPIRES_AT_OFFICE: 'expires_at_office',
  USER_TYPE_ADMIN: 'user_type_admin',
  TOKEN_ADMIN: 'token_admin',
  USER_INFO_ADMIN: 'user_info_admin',
  EXPIRES_AT_ADMIN: 'expires_at_admin',
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
  ADMIN: 'admin',
  SUPPORT: 'support',
  ADMIN_SUPPORT: 'admin-support',
  URL_BE_API: 'http://127.0.0.1:8000/api',
  STATUS_USER: {
    inactive: 0,
    active: 1,
    experience: 2,
    other: 3,
    all: 4,
    pause: 5
  },
  RUBY: 'ruby',
  AUTO_START: 'autoStart',
  IS_RUNNING: 'isRunning',
  PAUSE_TRACKING: 'pauseTracking',
  RUN_TRACKING_TIME_ACTION: 'runTrackingTimeAction',
  COLUMN_REPORT: 'column_report',
  FILTER_STATUS: 'filter_status',
  FILTER_STATUS_1: 'filter_status_1',
  FILTER_STATUS_2: 'filter_status_2',
  FILTER_KEYWORD: 'filter_keyword',
  FILTER_KEYWORD_1: 'filter_keyword_1',
  FILTER_KEYWORD_2: 'filter_keyword_2',
  FILTER_KEYWORD_NOTIFI: 'filter_keyword_notifi',
  FILTER_KEYWORD_CATEGORY: 'filter_keyword_category',
  FILTER_KEYWORD_PERSON: 'filter_keyword_person',
  FILTER_KEYWORD_DATA: 'filter_keyword_data',
  FILTER_PER_PAGE: 'per_page',
  FILTER_PER_PAGE_1: 'per_page_1'
}
